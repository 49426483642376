import React from 'react';
import styled from 'styled-components/macro';
import Link from 'gatsby-link';

import Button from '@material-ui/core/Button';

import TitleHeaderText from '../../components/TitleHeaderText';
import Slider from '../../views/LandingPage/Slider';

import { expData } from './const/gogetRecruitExpData';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  width: 1050px;
  margin: auto;
  @media (max-width: 1155px) {
    width: unset;
    margin: unset;
  }
  .flickity-viewport {
    padding-bottom: 2rem;
  }

  .flickity-prev-next-button.previous {
    left: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }
  .flickity-prev-next-button.next {
    right: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }

  .flickity-page-dots .dot {
    background: ${colors.primary};
  }
`;
const Image = styled.img`
  width: 320px;
  height: 650px;
  margin-right: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledButton = styled(Button)`
  && {
    color: ${colors.white};
    width: 240px;
    height: 60px;
    margin-bottom: 4rem;
    margin-top: 6rem;
    font-weight: bold;
    border-radius: 8px;
    font-size: ${fontSize.large};
  }
`;

const GoGetRecruitExpGoGetter = () => {
  const options = {
    wrapAround: true,
    freeScroll: false,
    fullscreen: true,
    adaptiveHeight: true,
    draggable: true,
    pageDots: true,
    contain: true,
    prevNextButtons: true
  };

  return (
    <MainDiv>
      <TitleHeaderText
        styledDesktop={{ marginBottom: '4rem' }}
        styledMobile={{
          marginLeft: '1rem',
          marginRight: '1rem',
          marginTop: '-4rem'
        }}
        text="Discover our experienced GoGetters"
      />
      <Slider options={options}>
        {expData.map(data => {
          return <Image key={data.id} src={data.image} alt={data.name} />;
        })}
      </Slider>
      <ButtonWrapper
        onClick={() => {
          window.analytics.track('body_clickthrough', {
            category: 'clickthrough',
            label: 'start_hiring',
            platform: 'static',
            destination: 'app.goget.my'
          });
        }}
      >
        <StyledButton
          component={Link}
          to="https://app.goget.my/"
          variant="contained"
          color="primary"
        >
          Start Hiring
        </StyledButton>
      </ButtonWrapper>
    </MainDiv>
  );
};

export default GoGetRecruitExpGoGetter;
