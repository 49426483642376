import AhmadShafwan from './assets/AhmadShafwan.png';
import MashoodAhmad from './assets/MashoodAhmad.png';
import NurulHidayah from './assets/NurulHidayah.png';
import FirdausLau from './assets/FirdausLau.png';

export const expData = [
  {
    id: 1,
    image: FirdausLau,
    name: 'Firdaus Lau'
  },
  {
    id: 2,
    image: MashoodAhmad,
    name: 'Mashood Ahmad'
  },
  {
    id: 3,
    image: NurulHidayah,
    name: 'Nurul Hidayah'
  },
  {
    id: 4,
    image: AhmadShafwan,
    name: 'Ahmad Shafwan'
  }
];
