import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const Image = styled.img`
  width: 55%;
  height: 100%;
  order: ${({ order }) => order && order};
  @media (max-width: 960px) {
    order: 0;
    width: 100%;
    height: 100%;
  }
`;
const Description = styled.p`
  font-weight: 400;
`;
const TextWrapper = styled.div`
  max-width: 400px;
  @media (max-width: 760px) {
    text-align: center;
  }
`;

const USPImageAndDescription = props => {
  const { data } = props;

  return (
    <Container key={data.id}>
      <Image
        order={data.order}
        src={data.image}
        alt="GoGet usp, GoGet recuit usp"
      />
      <TextWrapper>
        <>{data.title}</>
        <Description>{data.description}</Description>
      </TextWrapper>
    </Container>
  );
};

export default USPImageAndDescription;
