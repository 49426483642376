import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Layout from '../components/Layout';

import TopHeader from '../views/shared/TopHeader';

import FAQSection from '../views/shared/FAQ';
import ContactSection from '../components/ContactSection';
import HeaderSeo from '../components/HeaderSeo';

import GoGetRecruitUSP from '../views/GoGetRecruitPage/GoGetRecruitUSP';
import GoGetRecruitExpGoGetter from '../views/GoGetRecruitPage/GoGetRecruitExpGoGetter';

import BusinessBackground from '../assets/img/gogetRecruit/top-banner-desktop.svg';
import MobileBusinessBackground from '../assets/img/gogetRecruit/top-banner-mobile.svg';

import { FaqData } from '../views/GoGetRecruitPage/const/gogetRecruitFAQData';

import MobileBusinessFooterBackground from '../assets/img/business/MobileBusinessFooterBackground.png';
import DesktopFooterBackground from '../assets/img/business/DesktopFooterBackground.png';

import MetaImg from '../assets/img/meta/business.png';

import { SharedBackground } from '../assets/sharedStyle';

const BackgroundFooter = styled.div`
  ${SharedBackground};
  background-image: url(${DesktopFooterBackground});
  @media (max-width: 760px) {
    background-image: url(${MobileBusinessFooterBackground});
  }
`;
const ContactUsContainer = styled.div`
  margin-top: 80px;
  @media (max-width: 760px) {
    margin-top: 0px;
  }
`;

const GogetRecruit = props => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (hash === 'contact-us') {
      setTimeout(() => {
        const extraSize = mobile ? 350 : -10;
        const contactUs =
          document.getElementById('contact-us').offsetTop + extraSize;
        window.scroll({ top: contactUs, behavior: 'smooth' });
      }, 700);
    } else {
      window.scrollTo(0, 0);
    }
    window.addEventListener('resize', sizeHandler);
    return sizeHandler();
  }, [mobile]);

  const sizeHandler = () => {
    if (window.innerWidth <= 800) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  return (
    <>
      <HeaderSeo
        title="GoGet | GoGet Recruit"
        description="Get applications from experienced workers in minutes."
        image={MetaImg}
        keywords="goget, goget recruit, recruit"
        pathname={props.location.pathname}
      />
      <Layout isGoGetRecruit={true}>
        <TopHeader
          backgroundImg={BusinessBackground}
          mobileBackgroundImg={MobileBusinessBackground}
          title="Targeted Hiring"
          h4="Hiring"
          description="Get applications from experienced workers in minutes."
          btn1={{
            text: 'START HIRING',
            onClick: () => {
              window.analytics.track('body_clickthrough', {
                category: 'clickthrough',
                label: 'start_hiring',
                platform: 'static',
                destination: 'app.goget.my'
              });
              window.open('https://app.goget.my/');
            }
          }}
          secondary
        />
        <br />
        <GoGetRecruitUSP />
        <GoGetRecruitExpGoGetter />
        <BackgroundFooter>
          <FAQSection data={FaqData} />
          <ContactUsContainer id="contact-us">
            <ContactSection
              hideBackground
              title={<div> Let us help grow your business! </div>}
              subtext="Provide us with your details and we will get in touch."
            />
          </ContactUsContainer>
        </BackgroundFooter>
      </Layout>
    </>
  );
};

export default GogetRecruit;
