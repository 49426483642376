import React from 'react';

export const FaqData = [
  {
    question: 'What are the roles I can hire on GoGet Recruit?',
    content: (
      <>
        <br />
        <div>
          Hire for a variety of non-executive roles on GoGet Recruit ranging
          from the departments of Logistics, Sales/Marketing, Admin and
          Operations. Head on to{' '}
          <a href="https://app.goget.my/">app.goget.my</a> for more info.
        </div>
      </>
    )
  },
  {
    question: 'How does GoGet Recruit work?',
    content: (
      <>
        <br />
        <div>
          Businesses post their job vacancy on GoGet Recruit and the job is
          shown to targeted and active workers. You can contact the interested
          applicants directly.
        </div>
      </>
    )
  },
  {
    question: 'How do I get started with GoGet Recruit?',
    content: (
      <div>
        All you have to do is fill in your job requirements in less than 3
        minutes.{' '}
      </div>
    )
  },

  {
    question: 'What are the durations of hiring available on GoGet Recruit?',
    content: (
      <>
        <div>
          Flexible hiring with GoGet Recruit. You can hire experienced workers
          for <strong>part time, contract, and full time</strong> to fill your
          vacancy according to your needs.{' '}
        </div>
      </>
    )
  },
  {
    question: 'How long would my job listing last on GoGet Recruit? ',
    content: (
      <>
        <br />
        <div>
          Your job listing will stay on GoGet Recruit for{' '}
          <strong>30 days</strong> from the day it is being posted. You can
          extend the same job listing by reposting the job with new charges.{' '}
        </div>
      </>
    )
  },
  {
    question: 'What do I need to post a job?',
    content: (
      <>
        <br />
        <div>
          Posting your job vacancy on GoGet Recruit is easy and fast, just sign
          up for a GoGet account, and you can already start posting with GoGet
          credits.
        </div>
        <div>Tips to ensure you get the right applicants profiles:</div>
        <ul>
          <li>
            <strong>Get your GoGet profile verified:</strong> You’ll be asked
            for basic information about you and your company to help applicants
            apply with confidence.
          </li>
          <li>
            <strong>A job description:</strong> A detailed job description on
            the responsibilities and day-to-day tasks will increase your chances
            of attracting more applicants.
          </li>
          <li>
            <strong>Worker profile requirement:</strong> List down your ideal
            applicant's experience, certificates and language proficiency as
            detailed as possible to get the right hire.
          </li>
        </ul>
      </>
    )
  }
];
