import React from 'react';
import styled from 'styled-components';

import HighlyTarget from './assets/HighlyTarget.png';
import ExpWorkers from './assets/ExpWorkers.png';
import HireFast from './assets/HireFast.png';
import HireAffordably from './assets/HireAffordably.png';
import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

const PurpleText = styled.span`
  color: ${colors.secondary};
`;
const Title = styled.h3`
  font-size: ${fontSize.twoRem};
  @media (max-width: 760px) {
    font-size: ${fontSize.oneHalfRem};
  }
`;

export const uspData = [
  {
    id: 1,
    image: HighlyTarget,
    title: (
      <Title>
        Highly <PurpleText>targeted</PurpleText>
      </Title>
    ),
    description: (
      <>
        Reach over 100,000 active and engaged part time workers for longer-term
        non-executive job vacancies. All applicants are verified for quality and
        come with profiles of their job experiences.
      </>
    ),
    order: 1
  },
  {
    id: 2,
    image: ExpWorkers,
    title: (
      <Title>
        <PurpleText>Experienced</PurpleText> workers
      </Title>
    ),
    description: (
      <>
        Skip the hassle of downloading the CVs, you can screen applicants based
        on real on-job experiences and ratings directly in the app to ensure you
        select the best applicant.
      </>
    )
  },
  {
    id: 3,
    image: HireFast,
    title: (
      <Title>
        Hire <PurpleText>fast</PurpleText>
      </Title>
    ),
    description: (
      <>
        Scale up your hiring process anywhere with GoGet Recruit. Post a job
        vacancy on-the-go with GoGet App, and get applications within minutes!
      </>
    ),
    order: 1
  },
  {
    id: 4,
    image: HireAffordably,
    title: (
      <Title>
        Hire <PurpleText>affordably</PurpleText>
      </Title>
    ),
    description: (
      <>
        GoGet is here to help businesses stay cost effective, list your job from
        as low as RM149 on your first time (N.P. RM199). Use GoGet Recruit to
        bring in non-executive job workers when you need them the most.
      </>
    )
  }
];
