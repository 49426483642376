import React from 'react';
import styled from 'styled-components/macro';

import TitleHeaderText from '../../components/TitleHeaderText';
import USPImageAndDescription from '../shared/USPImageAndDescription';

import { uspData } from './const/gogetRecruitUspData';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
  width: 90%;
  margin-bottom: 168px;
`;
const Wrapper = styled.div``;

const HappeningInCommunity = () => {
  return (
    <MainDiv>
      <TitleHeaderText
        styledDesktop={{ marginTop: '3rem', marginBottom: 50 }}
        styledMobile={{ marginLeft: '1rem', marginRight: '1rem' }}
        text="Why GoGet Recruit"
      />
      <Wrapper>
        {uspData.map(data => (
          <USPImageAndDescription key={data.id} data={data} />
        ))}
      </Wrapper>
    </MainDiv>
  );
};

export default HappeningInCommunity;
